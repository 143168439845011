// import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// scroll bar
import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// apex-chart
import 'assets/third-party/apex-chart.css';

// project import
import App from './App';
import { store } from 'store';
import { Toaster } from '../node_modules/react-hot-toast/dist/index';
// ==============================|| MAIN - REACT DOM RENDER  ||============================= //

ReactDOM.render(
    <ReduxProvider store={store}>
        <BrowserRouter>
            <App />
            <Toaster
                toastOptions={{
                    style: {
                        fontSize: '15px',
                        fontWeight: 'bolder'
                    },
                    success: {
                        style: {
                            background: '#44D62C',
                            color: '#431C5B'
                        }
                    },
                    error: {
                        style: {
                            background: '#DA2453',
                            color: '#ffffff'
                        }
                    }
                }}
            />
        </BrowserRouter>
    </ReduxProvider>,
    document.getElementById('root')
);
