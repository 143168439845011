import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { Navigate } from '../../node_modules/react-router-dom/index';
import MinimalLayout from 'layout/MinimalLayout/index';

// Auth
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const Home = Loadable(lazy(() => import('pages/public/Home')));
const Error = Loadable(lazy(() => import('pages/404')));
const Forbidden = Loadable(lazy(() => import('pages/403')));
const Unknown = Loadable(lazy(() => import('pages/500')));
const ForgotPassword = Loadable(lazy(() => import('pages/public/ForgotPassword')));
const RecoveryPassword = Loadable(lazy(() => import('pages/public/RecoveryPassword')));
const SecureRegister = Loadable(lazy(() => import('pages/public/SecureRegister')));

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const ActiveSessions = Loadable(lazy(() => import('pages/admin/active-sessions/ActiveSessions')));
//Admin
const IndexAdmin = Loadable(lazy(() => import('pages/admin/IndexAdmin')));
const Users = Loadable(lazy(() => import('pages/admin/users/Users')));
const Rols = Loadable(lazy(() => import('pages/admin/rols/Rols')));

//Root
const Modules = Loadable(lazy(() => import('pages/root/modules/Modules')));
const GroupModules = Loadable(lazy(() => import('pages/root/group/GroupModules')));
const GetLink = Loadable(lazy(() => import('pages/root/get-link/GetLink')));
const EnterpriceList = Loadable(lazy(() => import('pages/root/enterprices/EnterpriceList')));

//Modulos
const Companies = Loadable(lazy(() => import('pages/companies/Companies')));
const Xml = Loadable(lazy(() => import('pages/xml/Xml')));
const Profile = Loadable(lazy(() => import('pages/profile/Profile')));
const Enterprice = Loadable(lazy(() => import('pages/root/enterprices/Enterprice')));
const Accounnts = Loadable(lazy(() => import('pages/accounts/Accounts')));
const PolicyTypes = Loadable(lazy(() => import('pages/policy-types/PolicyTypes')));
const AccountingSeat = Loadable(lazy(() => import('pages/accounting-seat/AccountingSeat')));
const Policies = Loadable(lazy(() => import('pages/policies/Policies')));
const BankAccounts = Loadable(lazy(() => import('pages/bank-accounts/BankAccounts')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (isLoggedIn, user = { user_id: "1" }) => [
    {
        path: '/auth/recovery-password/:code',
        element: <RecoveryPassword />
    },
    {
        path: '/auth/forgot-password',
        element: <ForgotPassword />
    },
    {
        path: '/auth/secure-register/:code',
        element: <SecureRegister />
    },
    {
        path: '/auth/login',
        element: <AuthLogin />
    },
    {
        path: '/root',
        element: isLoggedIn ? <MainLayout /> : <Navigate to="/auth/login" />,
        children: [
            {
                path: 'group-modules',
                element: parseInt(user?.user_id) === 1 ? <GroupModules /> : <Navigate to="/forbidden" />
            },
            {
                path: 'modules',
                element: parseInt(user?.user_id) === 1 ? <Modules /> : <Navigate to="/forbidden" />
            },
            {
                path: 'get-register-link',
                element: parseInt(user?.user_id) === 1 ? <GetLink /> : <Navigate to="/forbidden" />
            },
            {
                path: 'get-enterprice-list',
                element: parseInt(user?.user_id) === 1 ? <EnterpriceList /> : <Navigate to="/forbidden" />
            }
        ]
    },
    {
        path: '/app',
        element: isLoggedIn ? <MainLayout /> : <Navigate to="/auth/login" />,
        children: [
            {
                path: 'profile',
                element: <Profile />
            },
            {
                path: 'enterprice',
                element: <Enterprice />
            },
            {
                path: 'dashboard',
                element: <DashboardDefault />
            },
            {
                path: 'companies',
                element: <Companies />
            },
            {
                path: 'xml',
                element: <Xml />
            },
            {
                path: 'accounts',
                element: <Accounnts />
            },
            {
                path: 'bank-accounts',
                element: <BankAccounts />
            },
            {
                path: 'policy-types',
                element: <PolicyTypes />
            },
            {
                path: 'accounting-seat',
                element: <AccountingSeat />
            },
            {
                path: 'policies',
                element: <Policies />
            },
            {
                path: 'admin',
                children: [
                    {
                        path: '',
                        element: parseInt(user?.user_id) === 1 || parseInt(user?.link_register) === 1 ? <IndexAdmin /> : <Navigate to="/forbidden" />
                    },
                    {
                        path: 'users',
                        element: parseInt(user?.user_id) === 1 || parseInt(user?.link_register) === 1 ? <Users /> : <Navigate to="/forbidden" />
                    },
                    {
                        path: 'rols',
                        element: parseInt(user?.user_id) === 1 || parseInt(user?.link_register) === 1 ? <Rols /> : <Navigate to="/forbidden" />
                    },
                    {
                        path: 'active-sessions',
                        element: parseInt(user?.user_id) === 1 || parseInt(user?.link_register) === 1 ? <ActiveSessions user={user} /> : <Navigate to="/forbidden" />
                    }
                ]
            }
        ]
    },
    {
        path: '/',
        element: <MinimalLayout />,
        children: [
            { path: 'home', element: <Home /> },
            { path: '/', element: <Home /> }
        ]
    },
    {
        path: '/forbidden',
        element: <Forbidden />
    },
    {
        path: '/unknown',
        element: <Unknown />
    },
    {
        path: '*',
        element: <Error />
    }
];

export default MainRoutes;
