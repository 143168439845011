import axiosConfig from '../lib/axiosConfig.js';
import toast from 'react-hot-toast';

export const useAuth = () => {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: token } };
    const csrf = () => axiosConfig.get('/sanctum/csrf-cookie');

    const isLogged = async ({ setAuthenticated, setLoading, setUser }) => {
        setLoading(true);
        if (!token) {
            setAuthenticated(false);
            setLoading(false);
        } else {
            await axiosConfig
                .get('/api/is-logged', config)
                .then((res) => {
                    if (res.data.message === true) {
                        setAuthenticated(true);
                        getUserData({ setUser, setLoading });
                    }
                })
                .catch(() => {
                    // setAuthenticated(false);
                    // localStorage.removeItem('token');
                    toast.error('Sesión invalida o vencida, vuelve a iniciar sesión.');
                    setLoading(false)
                })
        }
    };

    const getUserData = async ({ setUser, setLoading }) => {
        await axiosConfig
            .get('/api/get-users-by-auth', config)
            .then((res) => {
                setUser(res.data.data)
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            });
    };

    const login = async ({ setLoading, data }) => {
        setLoading(true);
        await csrf();

        axiosConfig
            .post('/login', data)
            .then((res) => {
                let tkn = `${res.data.token_type} ${res.data.access_token}`;
                window.localStorage.setItem('token', tkn);
                toast.success(res.data.message);
                setTimeout(() => {
                    window.location.href = '/app/dashboard';
                }, 250);
            })
            .catch((err) => toast.error(err.response.data.message))
            .finally(() => setLoading(false));
    };

    const logout = async () => {
        await csrf();
        await axiosConfig
            .post('/logout', null, config)
            .then((res) => {
                localStorage.removeItem('token');
                toast.success(res.data.message);
                setTimeout(() => {
                    window.location.href = '/auth/login';
                }, 250);
            })
            .catch(() => {
                toast.error('Ocurrio un error al cerrar sesión');
            });
    };

    const forgotPassword = async ({ setLoading, data }) => {
        setLoading(true);
        await csrf();
        axiosConfig
            .post('/recovery/get-code', data, config)
            .then((res) => {
                toast.success('Correo enviado, verifíca tu bandeja de entrada.')
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
            .finally(() => setLoading(false));
    }

    const recoveryPassword = async ({ setLoading, data }) => {
        setLoading(true);
        await csrf();
        axiosConfig
            .post('/recovery/recovery-pass-auth', data, config)
            .then((res) => {
                toast.success(res.data.message);
                setTimeout(() => {
                    window.location.href = '/auth/login';
                }, 1500);
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
            .finally(() => setLoading(false));
    }

    const secureRegister = async ({ setLoading, data }) => {
        setLoading(true);
        await csrf();
        axiosConfig
            .post('/secure-register', data)
            .then((res) => {
                toast.success(res.data.message);
                setTimeout(() => {
                    window.location.href = '/auth/login';
                }, 1500);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => setLoading(false))
    }

    const getActiveSessions = async ({ setLoading, setSessions }) => {
        setLoading(true);
        axiosConfig
            .get('/api/get-active-sessions', config)
            .then((res) => {
                toast.success(res?.data?.message || 'Lista de sesiones activas.');
                setSessions(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const closeActiveSession = async ({ setDialogLoading, setOpen, handleActiveSessions, data }) => {
        setDialogLoading(true);
        await csrf();
        axiosConfig
            .post('/api/close-active-session', data, config)
            .then((res) => {
                handleActiveSessions();
                toast.success(res.data.message);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => setDialogLoading(false))
    }

    const requestRegisterLink = async ({ setLoading, data, navigate }) => {
        setLoading(true);
        await csrf();
        axiosConfig
            .post('/api/links/get-code', data, config)
            .then((res) => {
                toast.success(res.data.message);
                setTimeout(() => {
                    navigate('/app/dashboard');
                }, 1500);
            })
            .catch((err) => {
                console.log(err.response.data);
                toast.error(err.response.data.message);
            })
            .finally(() => setLoading(false));
    }

    return {
        login,
        logout,
        isLogged,
        forgotPassword,
        getUserData,
        secureRegister,
        getActiveSessions,
        closeActiveSession,
        recoveryPassword,
        requestRegisterLink
    };
};
