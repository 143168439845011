import { ReactComponent as zendataSindicato } from '../images/icons/zendata_sindicato.svg';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import ApartmentIcon from '@mui/icons-material/Apartment';

const icons = {
    zendataSindicato,
    AttachEmailIcon,
    ApartmentIcon
};

const root = (user) => {
    if (parseInt(user?.user_id) === 1) return {
        id: 'root',
        title: 'Root Views',
        type: 'group',
        children: [
            {
                id: 'modules',
                title: 'Modulos',
                type: 'item',
                url: '/root/modules',
                icon: icons.zendataSindicato,
                breadcrumbs: false
            },
            {
                id: 'group-modules',
                title: 'Grupos modulos',
                type: 'item',
                url: '/root/group-modules',
                icon: icons.zendataSindicato,
                breadcrumbs: false
            },
            {
                id: 'request-register-link',
                title: 'Link de registro',
                type: 'item',
                url: '/root/get-register-link',
                icon: icons.AttachEmailIcon,
                breadcrumbs: false
            },
            {
                id: 'enterprice-list',
                title: 'Empresas registradas',
                type: 'item',
                url: '/root/get-enterprice-list',
                icon: icons.ApartmentIcon,
                breadcrumbs: false
            }
        ]
    }

    return null;
}

export default root;