// project import
import modules from './modules';
import admin from './admin';
import root from './root';
import { useContext } from 'react';
import { UserContext } from 'contexts/UserContext';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = () => {
    const {user} = useContext(UserContext);
    const tmpItems = [];
    const tmpAdmin = admin(user);
    const tmpRoot = root(user);
    const tmpModules = modules(user);

    if (tmpAdmin !== null) tmpItems.push(tmpAdmin);
    if (tmpModules !== null) tmpItems.push(tmpModules);
    if (tmpRoot !== null) tmpItems.push(tmpRoot);

    return {
        items: tmpItems
    }
}

export default menuItems;
