// assets
import { InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as zendataDelete } from '../images/icons/zendata_delete.svg';
import { ReactComponent as zendataOrganigrama } from '../images/icons/zendata_organigrama.svg';
import { ReactComponent as zendataUsers } from '../images/icons/zendata_users.svg';
import { ReactComponent as zendataConfig } from '../images/icons/zendata_config.svg';

// icons
const icons = {
    InfoCircleOutlined,
    zendataDelete,
    zendataOrganigrama,
    zendataUsers,
    zendataConfig
};
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = (user) => {
    if (parseInt(user?.link_register) === 1 || parseInt(user?.user_id) === 1) return {
        id: 'group-admin',
        title: 'Administrar applicación',
        type: 'group',
        children: [
            {
                id: 'admin',
                title: 'Admin',
                type: 'item',
                url: '/app/admin',
                icon: icons.zendataConfig,
                breadcrumbs: false
            },
            {
                id: 'admin-rols',
                title: 'Roles',
                type: 'item',
                url: '/app/admin/rols',
                icon: icons.zendataOrganigrama,
                breadcrumbs: false
            },
            {
                id: 'admin-users',
                title: 'Usuarios',
                type: 'item',
                url: '/app/admin/users',
                icon: icons.zendataUsers,
                breadcrumbs: false
            },
            {
                id: 'admin-sessions',
                title: 'Sesiones activas',
                type: 'item',
                url: '/app/admin/active-sessions',
                icon: icons.zendataUsers,
                breadcrumbs: false
            }
        ]
    };

    return null;
}

export default dashboard;
