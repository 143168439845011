// project import
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { useEffect, useState, useLayoutEffect } from 'react';
import { useRoutes } from '../node_modules/react-router-dom/index';
import MainRoutes from 'routes/MainRoutes';
import { useAuth } from 'hooks/useAuth';
import { UserContext } from 'contexts/UserContext';
import { Box, CircularProgress } from '../node_modules/@mui/material/index';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
    const [user, setUser] = useState(null);
    const [authenticated, setAuthenticated] = useState(true);
    const [loading, setLoading] = useState(false);
    const { isLogged } = useAuth();
    const routing = useRoutes(MainRoutes(authenticated, user));

    useLayoutEffect(() => {
        isLogged({ setAuthenticated, setLoading, setUser });
    }, []);

    useEffect(() => {
        window.document.title = 'Zendata';
    }, []);
    
    return (
        <>
            {
                loading ?
                    <Box sx={{ whidth: '100%', height: '100vh' }}>
                        <CircularProgress />
                    </Box> :
                    <UserContext.Provider value={{ user, setUser }}>
                        <ThemeCustomization>
                            <ScrollTop>{routing}</ScrollTop>
                        </ThemeCustomization>
                    </UserContext.Provider>
            }
        </>

    );
};

export default App;
