// assets
import { DashboardOutlined } from '@ant-design/icons';
import { ReactComponent as zendataInicio } from '../images/icons/zendata_inicio.svg';
// icons
const icons = {
    DashboardOutlined,
    zendataInicio
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const modules = (user) => {
    return {
        id: 'group-modules',
        title: 'Modulos',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                url: '/app/dashboard',
                icon: icons.DashboardOutlined,
                breadcrumbs: false
            },
            parseInt(user?.user_id) === 1 || parseInt(user?.link_register) === 1 || (parseInt(user?.readd) === 1 && parseInt(user?.companies) > 1) ?
                {
                    id: 'companies',
                    title: 'Compañias',
                    type: 'item',
                    url: '/app/companies',
                    icon: icons.zendataInicio,
                    breadcrumbs: false
                } : {},
            parseInt(user?.user_id) === 1 || parseInt(user?.link_register) === 1 || (parseInt(user?.readd) === 1 && parseInt(user?.xml) > 1) ?
                {
                    id: 'xml',
                    title: 'XML',
                    type: 'item',
                    url: '/app/xml',
                    icon: icons.zendataInicio,
                    breadcrumbs: false
                } : {},
                {
                    id: 'accounts',
                    title: 'Cuentas',
                    type: 'item',
                    url: '/app/accounts',
                    icon: icons.zendataInicio,
                    breadcrumbs: false
                },
                {
                    id: 'bank-accounts',
                    title: 'Cuentas bancarias',
                    type: 'item',
                    url: '/app/bank-accounts',
                    icon: icons.zendataInicio,
                    breadcrumbs: false
                },
                {
                    id: 'policy-types',
                    title: 'Tipos de polizas',
                    type: 'item',
                    url: '/app/policy-types',
                    icon: icons.zendataInicio,
                    breadcrumbs: false
                },
                {
                    id: 'accounting-seat',
                    title: 'Asientos contables',
                    type: 'item',
                    url: '/app/accounting-seat',
                    icon: icons.zendataInicio,
                    breadcrumbs: false
                },
                {
                    id: 'policies',
                    title: 'Polizas',
                    type: 'item',
                    url: '/app/policies',
                    icon: icons.zendataInicio,
                    breadcrumbs: false
                }
        ]
    }

};

export default modules;
